import {
  EditorActionType,
  ICreateAwardAction,
  IInsertImageAction,
  IMoveImageAction,
  IReplaceImageAction,
  IRotateAction,
  ISplitAwardAction,
  IStackAction,
  ISwitchImagesAction,
} from '../../../types/EditorAction';
import { PayloadAction, createEntityAdapter, createSlice, current, nanoid } from '@reduxjs/toolkit';
import { IImage, ImageState } from '../../../types/IImage';
import { IAction } from '../../../types/EditorAction';
import { TId } from '../../../types/TId';
import {
  actionRewindReducer,
  addDocumentAction,
  getActiveImageData,
  getImageMask,
  getLinkedImages,
  makeImageStackAction,
  makeMultipleImageStackAction,
  mapNormalizedImageNew,
} from './utils';
import { IComplex } from '../../../types/IComplex';
import { DocSection } from '../../../types/DocSection';
import { toast } from 'react-toastify';
import { IComplexDocument } from '../../../types/IComplexDocument';
import { ALL_FILTER, IFilter } from '../../../types/IFilter';

export interface INormalizedImage extends IImage {
  docId: TId;
  docType: DocSection;
  deleted: boolean;
  touched: boolean;
  actions: IAction[];
  inserted?: boolean;
  mask?: ImageState;
}

export interface INormalizedComplexDocument extends Omit<IComplexDocument, 'images'> {
  images: TId[];
  touched: boolean;
  actions: IAction[];
}

export interface IInsertedImage extends IImage {
  // inserted?: boolean;
  mask?: ImageState;
}

export const imagesAdapter = createEntityAdapter<INormalizedImage>();
export const insertedImagesAdapter = createEntityAdapter<IInsertedImage>();
export const actionsAdapter = createEntityAdapter<IAction>();
export const awardsAdapter = createEntityAdapter<INormalizedComplexDocument>();

export interface IOperatorTaskSlice {
  complexId: TId | null;
  complexName: string | null;
  awardsFactCount: number | null;
  petition: INormalizedComplexDocument | null;
  award: ReturnType<typeof awardsAdapter.getInitialState>;
  image: ReturnType<typeof imagesAdapter.getInitialState>;
  action: ReturnType<typeof actionsAdapter.getInitialState>;
  activeSheet: {
    currentImage: TId | null;
    linkedImages: TId[];
  };
  activeDocument: { id: TId; docType: DocSection; barcode: string } | null;
  imageSelection: TId[];
  insertedImage: ReturnType<typeof insertedImagesAdapter.getInitialState>;
  insertedSelection: TId[];
  insertedActive: TId | null;
  filter: IFilter;
}

const initialState: IOperatorTaskSlice = {
  complexId: null,
  complexName: null,
  awardsFactCount: null,
  petition: null,
  award: awardsAdapter.getInitialState(),
  image: imagesAdapter.getInitialState(),
  action: actionsAdapter.getInitialState(),
  activeSheet: {
    currentImage: null,
    linkedImages: [],
  },
  activeDocument: null,
  imageSelection: [],
  insertedImage: insertedImagesAdapter.getInitialState(),
  insertedSelection: [],
  insertedActive: null,
  filter: ALL_FILTER,
};

const operatorTaskSlice = createSlice({
  name: 'operatorTask',
  initialState,
  reducers: {
    setNormalizedTask: (state, action: PayloadAction<IComplex | null>) => {
      if (action.payload) {
        state.complexId = action.payload.id;
        state.complexName = action.payload.name;
        state.awardsFactCount = action.payload.awardsFactCount;
        const images: INormalizedImage[] = [];
        const awards: INormalizedComplexDocument[] = [];
        const originalPetition = action.payload.petition;

        if (originalPetition) {
          state.petition = {
            id: originalPetition.id,
            barcode: originalPetition.barcode,
            viewed: originalPetition.viewed,
            images: [],
            touched: false,
            actions: [],
          };

          originalPetition.images.forEach(img => {
            state.petition?.images.push(img.id);
            images.push(mapNormalizedImageNew(originalPetition.id, true, img));
          });
        }

        action.payload.awards.forEach(award => {
          const normalizedAward: INormalizedComplexDocument = {
            id: award.id,
            barcode: award.barcode,
            viewed: award.viewed,
            images: [],
            touched: false,
            actions: [],
          };

          award.images.forEach(img => {
            normalizedAward.images.push(img.id);
            images.push(mapNormalizedImageNew(award.id, false, img));
          });
          awards.push(normalizedAward);
        });
        awardsAdapter.setAll(state.award, awards);
        imagesAdapter.setAll(state.image, images);
        state.action = actionsAdapter.getInitialState();
        state.imageSelection = [];
      } else {
        return initialState;
      }
    },
    setAwardsFactCount: (state, action: PayloadAction<number>) => {
      state.awardsFactCount = action.payload;
    },
    setActiveDocument: (
      state,
      action: PayloadAction<{ id: TId; docType: DocSection; barcode: string } | null>,
    ) => {
      state.activeDocument = action.payload;
      state.imageSelection = [];
      if (!action.payload) {
        const currentImageId = state.image.ids[0]?.toString();
        state.activeSheet.currentImage = currentImageId;
        state.activeSheet.linkedImages = getLinkedImages(state, currentImageId);
        return;
      }

      const document =
        action.payload.docType === DocSection.Petitions
          ? state.petition
          : state.award.entities[action.payload.id];

      state.activeSheet.currentImage = document ? document.images[0] : null;
      state.activeSheet.linkedImages = document ? getLinkedImages(state, document.images[0]) : [];
    },
    setDocumentViewed: (state, action: PayloadAction<{ id: TId; type: DocSection }>) => {
      const document =
        action.payload.type === DocSection.Petitions
          ? state.petition
          : state.award.entities[action.payload.id];

      if (document && document.id === action.payload.id) {
        document.viewed = true;
      }
    },
    setActiveImage: (state, action: PayloadAction<TId>) => {
      state.activeSheet.currentImage = action.payload;
      state.activeSheet.linkedImages = getLinkedImages(state, action.payload);
    },
    changeSelectedImages: (state, action: PayloadAction<TId>) => {
      if (state.imageSelection.includes(action.payload)) {
        state.imageSelection = state.imageSelection.filter(id => id !== action.payload);
      } else {
        state.imageSelection.push(action.payload);
      }
    },
    resetImageSelection: state => {
      state.imageSelection = [];
    },
    setInsertedActiveImage: (state, action: PayloadAction<TId>) => {
      state.insertedActive = action.payload;
    },
    changeInsertedSelection: (state, action: PayloadAction<TId>) => {
      if (state.insertedSelection.includes(action.payload)) {
        state.insertedSelection = state.insertedSelection.filter(id => id !== action.payload);
      } else {
        state.insertedSelection.push(action.payload);
      }
    },
    resetInsertedSelection: state => {
      state.insertedSelection = [];
    },
    setInsertedImages: (state, action: PayloadAction<IImage[]>) => {
      insertedImagesAdapter.setAll(state.insertedImage, action.payload);
      state.insertedSelection = [];
      state.insertedActive = action.payload[0].id;
    },
    chancelLastAction: state => {
      if (!state.action.ids.length) return;
      const lastId = state.action.ids[state.action.ids.length - 1];
      if (!lastId) return;
      const actionToDelete = state.action.entities[lastId];
      if (!actionToDelete) return;
      actionsAdapter.removeOne(state.action, lastId);
      actionRewindReducer(state, actionToDelete);
    },
    rotateImage: (state, { payload }: PayloadAction<number>) => {
      const isMultiple = state.imageSelection.length;
      let { imageId, image } = getActiveImageData(state);
      if (isMultiple) {
        imageId = state.imageSelection[0];
        image = state.image.entities[imageId] ?? null;
      }

      if (!imageId || !image) {
        toast.error('Не удалось получить информацию об активном изображении!');
        return;
      }

      if (!isMultiple && image.deleted) {
        toast.error('Изображение удалено!');
        return;
      }

      const linkedIds = isMultiple ? state.imageSelection.filter(id => id !== imageId) : [];

      const action = makeImageStackAction(state, image, EditorActionType.Rotate, linkedIds);

      const imageAction: IRotateAction = {
        id: action.id,
        type: EditorActionType.Rotate,
        payload,
      };

      const linkedImages = linkedIds
        .map(id => state.image.entities[id])
        .filter(Boolean) as INormalizedImage[];

      const images = [image, ...linkedImages];

      const documentIds = images.map(img => img.docId);

      if (documentIds.some(id => id !== documentIds[0])) {
        toast.error('Выберите изображения принадлежащие к одному документу!');
        return;
      }

      addDocumentAction(state, action, image.docType, image.docId);
      if (images.length) {
        imagesAdapter.updateMany(
          state.image,
          images.map(img => {
            return {
              id: img.id,
              changes: { actions: [...img.actions, imageAction], touched: true },
            };
          }),
        );
      }
    },
    deleteImage: state => {
      const { imageId, image } = getActiveImageData(state);
      if ((!imageId || !image) && !state.imageSelection.length) {
        toast.error('Не удалось получить информацию об активном изображении!');
        return;
      }

      if (state.imageSelection.length && !state.activeDocument) {
        toast.error('Выберите активный документ внутри задания для множественного удаления!');
        return;
      }

      const idsToDelete: TId[] = [];
      if (state.imageSelection.length) {
        state.imageSelection.forEach(id => {
          if (!idsToDelete.includes(id)) {
            idsToDelete.push(id);
          }
          const linkedImages = getLinkedImages(state, id);
          linkedImages.forEach(linkedId => {
            if (!idsToDelete.includes(linkedId)) {
              idsToDelete.push(linkedId);
            }
          });
        });
      }

      const deletedImageIds = state.imageSelection.length
        ? idsToDelete
        : [imageId!, ...state.activeSheet.linkedImages];

      const docImage = state.image.entities[deletedImageIds[0]] as INormalizedImage;

      const action = makeMultipleImageStackAction(
        state,
        deletedImageIds,
        docImage.docType,
        docImage.docId,
        EditorActionType.DeleteImage,
      );

      addDocumentAction(state, action, docImage.docType, docImage.docId);

      deletedImageIds.forEach(imgId => {
        const img = state.image.entities[imgId];
        if (!img) return;
        const newAction = { id: action.id, type: EditorActionType.DeleteImage };
        imagesAdapter.updateOne(state.image, {
          id: imgId,
          changes: {
            actions: [...img.actions, newAction],
            touched: true,
            deleted: true,
            mask: ImageState.Deleted,
          },
        });
      });
    },
    toggleImportance: state => {
      const { imageId, image } = getActiveImageData(state);
      if (!imageId || !image) return;

      if (image.deleted) {
        toast.error('Недопустимая операция! Изображение удалено!');
        return;
      }

      if (image.group !== null) {
        toast.error('Недопустимая операция! Часть листа формата А3!');
        return;
      }

      const action = makeImageStackAction(state, image, EditorActionType.ImportantImage);

      const actions = [...image.actions, { id: action.id, type: action.type }];

      addDocumentAction(state, action, image.docType, image.docId);
      const changes: Partial<INormalizedImage> = {
        actions,
        touched: true,
        important: !image.important,
      };

      imagesAdapter.updateOne(state.image, {
        id: imageId,
        changes: {
          ...changes,
          mask: getImageMask({ ...image, ...changes }),
        },
      });
    },
    makeImageRecognized: state => {
      if (!state.activeSheet.currentImage && !state.imageSelection.length) {
        toast.error('Ни одно изображение не выделено!');
        return;
      }

      if (state.imageSelection.length && !state.activeDocument) {
        toast.error('Для продолжения выберите один документ из списка!');
        return;
      }

      const selectedIds = state.imageSelection.length
        ? state.imageSelection
        : [state.activeSheet.currentImage!];

      const selectedImages = selectedIds
        .map(imgId => state.image.entities[imgId])
        .filter(img => img && img.important && !img.recognized) as INormalizedImage[];

      if (!selectedImages.length) {
        toast.error('Не выбрано ни одно изображение, к которому можно применить данное действие!');
        return;
      }
      const { docType, docId } = selectedImages[0];
      const action = makeMultipleImageStackAction(
        state,
        selectedImages.map(img => img.id),
        docType,
        docId,
        EditorActionType.MakeImageRecognized,
      );
      addDocumentAction(state, action, docType, docId);
      selectedImages.forEach(img => {
        const newAction = { id: action.id, type: EditorActionType.MakeImageRecognized };
        imagesAdapter.updateOne(state.image, {
          id: img.id,
          changes: {
            actions: [...img.actions, newAction],
            touched: true,
            recognized: true,
            needRecognize: false,
            mask: img.important ? undefined : ImageState.Empty,
          },
        });
      });
    },
    changeAwardBarcode: (state, { payload }: PayloadAction<{ id: string; barcode: string }>) => {
      const award = state.award.entities[payload.id];
      if (!award) {
        toast.error('Не удалось определить активный наградной лист!');
        return;
      }
      award.barcode = payload.barcode;

      const action: IStackAction = {
        id: nanoid(),
        type: EditorActionType.SetBarcode,
        payload: {
          awards: [payload.id],
        },
      };

      actionsAdapter.addOne(state.action, action);
    },
    splitAward: (state, { payload }: PayloadAction<string>) => {
      if (!state.activeDocument) return;
      const award = state.award.entities[state.activeDocument.id];
      const awardIndex = state.award.ids.findIndex(id => id === state.activeDocument?.id);
      if (!award || awardIndex < 0) {
        toast.error('Не удалось определить активный наградной лист или его порядковый номер!');
        return;
      }
      const currentImageIndex = award.images.findIndex(id => id === state.activeSheet.currentImage);
      if (currentImageIndex < 0) {
        toast.error(
          'Не удалось определить порядковый номер активного изображения внутри наградного листа!',
        );
        return;
      }

      const oldAwardImages = award.images.slice(0, currentImageIndex);
      const newAwardImages = award.images.slice(currentImageIndex);

      const newAwardId = nanoid();

      const action: IStackAction = {
        id: nanoid(),
        type: EditorActionType.SplitAward,
        payload: {
          awards: [award.id, newAwardId],
        },
      };

      actionsAdapter.addOne(state.action, action);

      const splitAction: ISplitAwardAction = {
        id: action.id,
        type: EditorActionType.SplitAward,
        payload: newAwardImages,
      };

      const createAction: ICreateAwardAction = {
        id: action.id,
        type: EditorActionType.CreateAward,
      };

      const actions = [...award.actions, splitAction];
      const changes: Partial<INormalizedComplexDocument> = {
        actions,
        images: oldAwardImages,
        touched: true,
      };
      awardsAdapter.updateOne(state.award, { id: award.id, changes });

      const awards = awardsAdapter.getSelectors().selectAll(state.award);

      newAwardImages.forEach(imgId => {
        const img = state.image.entities[imgId];
        if (!img) return;
        imagesAdapter.updateOne(state.image, {
          id: imgId,
          changes: {
            docId: newAwardId,
          },
        });
      });

      const newAward: INormalizedComplexDocument = {
        id: newAwardId,
        touched: true,
        actions: [createAction],
        images: newAwardImages,
        barcode: payload,
        viewed: true,
      };

      awards.splice(awardIndex + 1, 0, newAward);
      awardsAdapter.setAll(state.award, awards);
    },
    deleteAward: state => {
      if (!state.activeDocument) return;
      const award = state.award.entities[state.activeDocument.id];
      const awardIndex = state.award.ids.findIndex(id => id === state.activeDocument!.id);
      if (!award || awardIndex < 0) {
        toast.error('Что-то пошло не так!');
        return;
      }

      const action: IStackAction = {
        id: nanoid(),
        type: EditorActionType.DeleteAward,
        payload: {
          awards: [state.activeDocument.id],
        },
      };
      actionsAdapter.addOne(state.action, action);
      awardsAdapter.updateOne(state.award, {
        id: award.id,
        changes: { actions: [...award.actions, action], touched: true },
      });
    },
    insertAward: (state, { payload }: PayloadAction<{ index: number; barcode: string }>) => {
      if (!state.activeDocument || state.activeDocument.docType !== DocSection.Awards) {
        toast.error('Для продолжения выберите наградной лист!');
        return;
      }

      const newAwardId = nanoid();

      const action: IStackAction = {
        id: nanoid(),
        type: EditorActionType.CreateAward,
        payload: {
          awards: [newAwardId],
        },
      };

      const newAward: INormalizedComplexDocument = {
        id: newAwardId,
        barcode: payload.barcode,
        touched: true,
        actions: [action],
        images: [],
        viewed: false,
      };

      actionsAdapter.addOne(state.action, action);
      const awards = awardsAdapter.getSelectors().selectAll(state.award);
      awards.splice(payload.index, 0, newAward);
      awardsAdapter.setAll(state.award, awards);
    },
    changeImageOrder: (state, { payload }: PayloadAction<number>) => {
      if (!state.activeSheet.currentImage) {
        toast.error('Выберите изображение для перемещения!');
        return;
      }

      const { image, index } = getActiveImageData(state);

      if (!image || index < 0 || index === undefined) {
        toast.error('Не удалось определить активное изображение либо его порядковый номер!');
        return;
      }

      if (image.docType === DocSection.Awards && index < 4) {
        toast.error('Запрещено перемещать первые 4 изображения наградного листа!');
        return;
      }

      const doc =
        image.docType === DocSection.Petitions ? state.petition : state.award.entities[image.docId];

      if (!doc) {
        toast.error('Что-то пошло не так');
        return;
      }

      const linkedIndex = doc.images.findIndex(id => id === state.activeSheet.linkedImages[0]);

      const minIndex = Math.min(index, linkedIndex);
      const sheetIds = [state.activeSheet.currentImage, ...state.activeSheet.linkedImages];

      if (minIndex < 0) {
        toast.error('Что-то пошло не так');
        return;
      }
      if (
        (payload === -1 && image.docType === DocSection.Petitions && minIndex < 2) ||
        (payload === -1 && image.docType === DocSection.Awards && minIndex < 6)
      ) {
        toast.warn('Невозможно передвинуть образы!');
        return;
      }

      if (payload === 1 && minIndex >= doc.images.length - 2) {
        toast.warn('Невозможно передвинуть образы!');
        return;
      }

      const action: IMoveImageAction = {
        id: nanoid(),
        type: EditorActionType.MoveImage,
        payload: current(doc.images),
      };

      [doc.images[minIndex], doc.images[minIndex + payload * 2]] = [
        doc.images[minIndex + payload * 2],
        doc.images[minIndex],
      ];

      [doc.images[minIndex + 1], doc.images[minIndex + 1 + payload * 2]] = [
        doc.images[minIndex + 1 + payload * 2],
        doc.images[minIndex + 1],
      ];

      const shortAction = {
        id: action.id,
        type: EditorActionType.MoveImage,
        payload,
      };

      actionsAdapter.addOne(state.action, action);
      doc.actions.push(shortAction);
      doc.touched = true;

      doc.images.forEach(img => {
        const isMovedSheet = sheetIds.includes(img);

        const image = state.image.entities[img];
        if (!image) return;
        if (isMovedSheet) {
          image.actions.push({
            id: action.id,
            type: EditorActionType.MoveImage,
          });
          image.touched = true;
        }
      });
    },
    switchImages: (state, { payload }: PayloadAction<[string, string]>) => {
      const firstSheet = [payload[0], ...getLinkedImages(state, payload[0])];
      const secondSheet = [payload[1], ...getLinkedImages(state, payload[1])];

      const sameSheetAll = firstSheet.sort().join(',') === secondSheet.sort().join(',');
      const img = state.image.entities[payload[0]];
      if (!img) {
        toast.error('Не удалось определить изображение!');
        return;
      }
      const { docId, docType } = img;
      const doc = docType === DocSection.Petitions ? state.petition : state.award.entities[docId];

      if (!doc) {
        toast.error('Что-то пошло не так!');
        return;
      }

      const action: ISwitchImagesAction = {
        id: nanoid(),
        type: EditorActionType.SwitchImages,
        payload: current(doc.images),
      };

      if (sameSheetAll) {
        actionsAdapter.addOne(state.action, action);
        doc.actions.push(action);
        doc.touched = true;

        const firstIndex = doc.images.findIndex(id => id === payload[0]);
        const secondIndex = doc.images.findIndex(id => id === payload[1]);

        if (firstIndex < 0 || secondIndex < 0) {
          toast.error('Что-то пошло не так!');
          return;
        }

        [doc.images[firstIndex], doc.images[secondIndex]] = [
          doc.images[secondIndex],
          doc.images[firstIndex],
        ];
        const img1 = state.image.entities[payload[0]];
        const img2 = state.image.entities[payload[1]];
        if (!img1 || !img2) {
          toast.error('Что-то пошло не так!');
          return;
        }
        img1.touched = true;
        img1.actions.push(action);
        img2.touched = true;
        img2.actions.push(action);
      } else {
        const sheet1image1 = state.image.entities[firstSheet[0]];
        const sheet1image2 = state.image.entities[firstSheet[1]];

        const sheet2image1 = state.image.entities[secondSheet[0]];
        const sheet2image2 = state.image.entities[secondSheet[1]];

        if (!sheet1image1 || !sheet1image2 || !sheet2image1 || !sheet2image2) {
          toast.error('Что-то пошло не так!');
          return;
        }

        const images = [sheet1image1, sheet1image2, sheet2image1, sheet2image2];

        if (sheet1image1.docId !== sheet2image1.docId) {
          toast.error('Замена возможна в рамках одного документа!');
          return;
        }

        if (images.some(img => img!.deleted)) {
          toast.error('Недопустимы операции перемещения с удаленным листом!');
          return;
        }

        const indexes = images.map(img => doc.images.findIndex(id => id === img.id)).sort();

        if (indexes.includes(-1)) {
          toast.error('Что-то пошло не так!');
          return;
        }

        if (images[0]!.docType === DocSection.Awards && indexes.some(i => i < 4)) {
          toast.error('Нельзя перемещать первые изображения наградного листа!');
          return;
        }

        actionsAdapter.addOne(state.action, action);
        doc.actions.push(action);
        doc.touched = true;

        images.forEach(i => {
          i!.actions.push(action);
          i!.touched = true;
        });

        [doc.images[indexes[0]], doc.images[indexes[2]]] = [
          doc.images[indexes[2]],
          doc.images[indexes[0]],
        ];

        [doc.images[indexes[1]], doc.images[indexes[3]]] = [
          doc.images[indexes[3]],
          doc.images[indexes[1]],
        ];
      }
    },

    resetInserted: state => {
      insertedImagesAdapter.removeAll(state.insertedImage);
      state.insertedSelection = [];
      state.insertedActive = null;
    },
    insertImages: (state, { payload }: PayloadAction<number>) => {
      if (!state.insertedImage.ids.length || !state.activeDocument) {
        toast.error('Что-то пошло не так!');
        return;
      }

      let docType: DocSection | undefined = undefined;

      if (state.activeSheet.currentImage) {
        docType = state.image.entities[state.activeSheet.currentImage]?.docType;
      } else if (state.activeDocument.barcode.startsWith('21')) {
        docType = DocSection.Petitions;
      } else if (state.activeDocument.barcode.startsWith('29')) {
        docType = DocSection.Awards;
      }

      if (!docType) {
        console.error('Секция документа не определена!');
        return;
      }

      const document =
        docType === DocSection.Awards
          ? state.award.entities[state.activeDocument.id]
          : state.petition;

      if (!document) {
        console.error('Документ не найден!');
        return;
      }

      const insertedIds = state.insertedSelection.length
        ? state.insertedSelection
        : state.insertedImage.ids.map(id => id.toString());

      if (insertedIds.length % 2 !== 0) {
        toast.error('Выберите четное количество образов для добавления!');
        return;
      }

      const shortAction: IAction = {
        id: nanoid(),
        type: EditorActionType.InsertImage,
      };

      const fullAction: IInsertImageAction = {
        id: shortAction.id,
        type: EditorActionType.InsertImage,
        payload: {
          section: docType,
          data: current(document),
          insertedImages: insertedImagesAdapter.getSelectors().selectAll(state.insertedImage),
        },
      };

      actionsAdapter.addOne(state.action, fullAction);

      const docActions = [...document.actions, shortAction];

      const update = {
        id: document.id,
        changes: { actions: docActions, touched: !!docActions.length },
      };

      if (docType === DocSection.Petitions) {
        state.petition!.actions = docActions;
        state.petition!.touched = !!docActions.length;
      } else {
        awardsAdapter.updateOne(state.award, update);
      }

      const insertedImages = insertedIds.map(id =>
        insertedImagesAdapter.getSelectors().selectById(state.insertedImage, id),
      ) as IInsertedImage[];

      document.images.splice(payload, 0, ...insertedIds);
      document.touched = true;

      const images: INormalizedImage[] = insertedImages.map(img =>
        mapNormalizedImageNew(document.id, docType === DocSection.Petitions, img),
      );

      imagesAdapter.addMany(state.image, images);
      insertedIds.forEach(imgId => {
        const image = state.image.entities[imgId];
        if (!image) return;
        image.inserted = true;
        image.mask = ImageState.Added;
        insertedImagesAdapter.updateOne(state.insertedImage, {
          id: imgId,
          changes: { mask: ImageState.Inserted },
        });
      });
    },
    replaceImage: (state, { payload }: PayloadAction<TId>) => {
      if (!(state.imageSelection.length === 1 && state.insertedSelection.length === 1)) {
        toast.error(
          'Выберите одно изображение в левой части и одно изображение в правой части для замены!',
        );
        return;
      }

      const imageToReplace = state.image.entities[state.imageSelection[0]];
      const newImage = state.insertedImage.entities[payload];

      if (!imageToReplace || !newImage) {
        console.error('Изображение не найдено!');
        return;
      }

      const document =
        imageToReplace!.docType === DocSection.Petitions
          ? state.petition
          : state.award.entities[imageToReplace.docId];

      if (!document) {
        console.error('Исходный документ не найден!');
        return;
      }

      const index = document.images.findIndex(id => id === imageToReplace.id);

      if (index < 0) {
        toast.error('Что-то пошло не так!');
        return;
      }

      const action: IReplaceImageAction = {
        id: nanoid(),
        type: EditorActionType.ReplaceImage,
        payload: {
          image: imageToReplace,
          index,
          insertedImages: insertedImagesAdapter.getSelectors().selectAll(state.insertedImage),
        },
      };

      document.images.splice(index, 1, newImage.id);
      document.actions.push(action);
      document.touched = true;

      const image: INormalizedImage = mapNormalizedImageNew(
        imageToReplace.docId,
        imageToReplace.docType === DocSection.Petitions,
        newImage,
      );
      image.mask = ImageState.Added;
      image.inserted = true;

      imagesAdapter.addOne(state.image, image);
      actionsAdapter.addOne(state.action, action);
      insertedImagesAdapter.updateOne(state.insertedImage, {
        id: state.insertedSelection[0],
        changes: { mask: ImageState.Inserted },
      });
      state.imageSelection = [];
      state.insertedSelection = [];
    },

    recognizeSelection: (state, { payload }: PayloadAction<TId[]>) => {
      if (!state.activeDocument) {
        toast.error('Выберите активный документ!');
        return;
      }
      const action = makeMultipleImageStackAction(
        state,
        payload,
        state.activeDocument.docType,
        state.activeDocument.id,
        EditorActionType.RecognizeSelected,
      );

      const shortAction = {
        id: action.id,
        type: EditorActionType.RecognizeSelected,
      };

      if (state.activeDocument.docType === DocSection.Petitions && state.petition) {
        state.petition.actions.push(shortAction);
        state.petition.touched = true;
      } else if (state.activeDocument.docType === DocSection.Awards) {
        const doc = state.award.entities[state.activeDocument.id];
        if (!doc) return;
        doc.actions.push(shortAction);
        doc.touched = true;
      }

      payload.forEach(imageId => {
        const img = state.image.entities[imageId];
        if (!img) return;
        img.actions = [
          ...img.actions,
          {
            ...shortAction,
            payload: { recognized: img.recognized, needRecognize: img.needRecognize },
          },
        ];
        img.touched = true;
        img.recognized = false;
        img.needRecognize = true;
        img.mask = getImageMask(img);
      });
    },
    // recognizeAll: state => {
    //   state.image.ids.forEach(imgId => {
    //     imagesAdapter.updateOne(state.image, { id: imgId, changes: { recognized: false } });
    //   });
    // },
    updateFilter: (state, { payload }: PayloadAction<Partial<IFilter>>) => {
      state.filter = { ...state.filter, ...payload };
    },
  },
});

export const {
  setNormalizedTask,
  setAwardsFactCount,
  setActiveDocument,
  setDocumentViewed,
  setActiveImage,
  changeSelectedImages,
  resetImageSelection,
  setInsertedImages,
  setInsertedActiveImage,
  changeInsertedSelection,
  resetInsertedSelection,
  rotateImage,
  chancelLastAction,
  toggleImportance,
  makeImageRecognized,
  deleteImage,
  deleteAward,
  splitAward,
  changeAwardBarcode,
  insertAward,
  resetInserted,
  insertImages,
  replaceImage,
  changeImageOrder,
  switchImages,
  recognizeSelection,
  updateFilter,
} = operatorTaskSlice.actions;

export default operatorTaskSlice.reducer;
