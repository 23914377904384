import { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard, Scrollbar } from 'swiper/modules';
import Slide from './Slide';
import { TId } from '../../types/TId';
import Card from '../ui/Card';
import classNames from 'classnames';
import { ChevronIcon } from '../ui/icons/Icons';
import { forceCheck } from 'react-lazyload';

import styles from './Slider.module.scss';
import { ISlide } from '../../types/ISlide';
import { ImageState } from '../../types/IImage';
import { FaTimes } from 'react-icons/fa';

interface Props<T> {
  headerText?: string;
  secondaryText?: string;
  items: T[];
  selection: TId[];
  activeId?: TId;
  onSlideClick: (item: T) => void;
  onSlideSelect: (item: T) => void;
  className?: string;
  docId?: TId | null;
  pairedSelection?: boolean;
  a3start?: boolean;
  selectionChecker?: (item: T) => boolean;
  readOnly?: boolean;
  keyboard?: boolean;
  onSelectionReset?: () => void;
}

const Slider = <T extends ISlide>({
  headerText,
  secondaryText,
  items,
  docId,
  activeId,
  className,
  selection,
  pairedSelection,
  onSlideClick,
  onSlideSelect,
  a3start,
  selectionChecker,
  readOnly,
  keyboard,
  onSelectionReset,
}: Props<T>) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    if (swiper && !swiper.destroyed && docId) {
      swiper.slideTo(0);
    }
  }, [swiper, docId]);

  useEffect(() => {
    const activeSlideIndex = items.findIndex(i => i.id === activeId);
    if (swiper && !swiper.destroyed && activeSlideIndex >= 0) {
      swiper.slideTo(activeSlideIndex);
      forceCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId, swiper]);

  const scrollFast = useCallback(
    (n: number) => {
      if (swiper) {
        swiper.slideTo(swiper.activeIndex + n);
      }
    },
    [swiper],
  );

  const keyPressHandler = useCallback(
    (swiper: SwiperClass, key: string) => {
      const activeIndex = items.findIndex(img => img.id === activeId);
      if (+key === 33 || +key === 38) {
        const nextIndex =
          activeIndex > 0 ? activeIndex - 1 : activeIndex === 0 ? 0 : swiper.activeIndex;
        onSlideClick(items[nextIndex]);
      }
      if (+key === 34 || +key === 40) {
        const nextIndex =
          activeIndex < 0
            ? swiper.activeIndex
            : activeIndex === items.length - 1
              ? activeIndex
              : activeIndex + 1;

        onSlideClick(items[nextIndex]);
      }
    },
    [activeId, items, onSlideClick],
  );

  return (
    <Card className={classNames(styles.slider, className, 'pr-8')}>
      {!!selection?.length && (
        <div className={styles.selectCount}>
          {selection.length}
          {onSelectionReset && (
            <button onClick={onSelectionReset} className={styles.selectionResetButton}>
              <FaTimes />
            </button>
          )}
        </div>
      )}
      {headerText && <p className={classNames(styles.headerText)}>{headerText}</p>}
      {secondaryText && <p className={styles.secondaryText}>{secondaryText}</p>}
      <button
        className={classNames(styles.sliderButton, styles.sliderButtonPrev)}
        onClick={() => scrollFast(-10)}
      >
        <ChevronIcon />
      </button>
      <Swiper
        autoHeight={true}
        onSwiper={setSwiper}
        scrollbar={{
          draggable: true,
          enabled: true,
          dragSize: 40,
        }}
        slidesPerView={'auto'}
        direction="vertical"
        mousewheel={{ sensitivity: 50 }}
        modules={[Mousewheel, Keyboard, Scrollbar]}
        keyboard={{ enabled: keyboard, onlyInViewport: true }}
        onKeyPress={keyPressHandler}
        className={styles.swiper}
        onSlideChangeTransitionEnd={forceCheck}
        onScrollbarDragEnd={forceCheck}
      >
        {items.map((image, i) => (
          <SwiperSlide
            key={image.id}
            className={a3start && (i === 1 || i === 2) ? styles.a3middle : undefined}
          >
            <Slide
              isReady={!!swiper}
              src={image.src}
              rotateDeg={image.rotateDeg ?? 0}
              mod={image.mod as ImageState}
              active={image.id === activeId}
              selected={
                selectionChecker ? selectionChecker(image) : !!selection?.includes(image.id)
              }
              showCheckbox={readOnly ? false : pairedSelection ? i % 2 === 0 : true}
              touched={image.touched}
              onSelect={() => onSlideSelect(image)}
              onClick={() => onSlideClick(image)}
              a3middle={a3start && (i === 1 || i === 2)}
              hidden={image.hidden}
              className={styles.slide}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        className={classNames(styles.sliderButton, styles.sliderButtonNext)}
        onClick={() => scrollFast(10)}
      >
        <ChevronIcon />
      </button>
    </Card>
  );
};
export default Slider;
